import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import get from 'lodash.get';
import { themeVal, glsp, media, multiply } from '@devseed-ui/theme-provider';

import Constrainer from '../styles/constrainer';

import Media, { MediaItem, MediaVideo } from './media';
import LongForm from './long-form';
import { MosaicFeatures, MosaicNumbers } from './mosaic';
import Diptych from './diptych';

const constrainerPadding = (size) =>
  glsp(multiply(themeVal(`layout.gap.${size}`), 2));

const FluidConstrainer = styled(Constrainer)`
  width: auto;
`;

const MissingBlock = styled.div`
  background-color: #ead688;
  color: #cf3f02;
  padding: 1rem;
`;

const ImageBlockMedia = styled(Media)`
  ${MediaItem} {
    display: flex;
    justify-content: center;

    .gatsby-image-wrapper {
      max-width: 100% !important;
      width: calc(100vw - ${constrainerPadding('xsmall')});

      ${media.smallUp`
        width: calc(100vw - ${constrainerPadding('small')});
      `}

      ${media.mediumUp`
        width: calc(100vw - ${constrainerPadding('medium')});
      `}

      ${media.largeUp`
        width: calc(100vw - ${constrainerPadding('large')});
      `}

      ${media.xlargeUp`
        width: calc(100vw - ${constrainerPadding('xlarge')});
      `}
    }
  }
`;

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: text
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockText = ({ body }) => {
  return <LongForm dangerouslySetInnerHTML={{ __html: body }} />;
};

BlockText.propTypes = {
  body: T.string
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: pull-quote
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockPullQuote = ({ body, quote }) => {
  return (
    <LongForm pullQuote={quote} dangerouslySetInnerHTML={{ __html: body }} />
  );
};

BlockPullQuote.propTypes = {
  quote: T.string,
  body: T.string
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: feature-list
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockFeatureList = ({ items }) => {
  return <MosaicFeatures items={items} />;
};

BlockFeatureList.propTypes = {
  items: T.arrayOf(
    T.shape({
      body: T.string,
      title: T.string
    })
  )
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: number-list
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockNumberList = ({ items }) => {
  return <MosaicNumbers items={items} />;
};

BlockNumberList.propTypes = {
  items: T.arrayOf(
    T.shape({
      value: T.string,
      unit: T.string,
      title: T.string,
      body: T.string
    })
  )
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: image
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockImage = ({ size, decoration, url, attribution, caption }) => {
  // Some images like gif and svg can't be processes by ImageSharp. In those
  // cases use the unprocessed version.
  const srcProps = url.childImageSharp
    ? {
        fluid: url.childImageSharp.fluid
      }
    : {
        src: url.publicURL
      };

  const ImageBlockMediaEl =
    !size || size === 'default' ? ImageBlockMedia : Media;

  const content = (
    <ImageBlockMediaEl
      size={size}
      decoration={decoration}
      {...srcProps}
      alt='Image'
      attribution={attribution}
      dangerouslySetInnerHTML={{ __html: caption }}
    />
  );
  return !size || size === 'default' ? (
    <FluidConstrainer>{content}</FluidConstrainer>
  ) : (
    content
  );
};

BlockImage.propTypes = {
  size: T.oneOf(['large', 'cover', 'default']),
  decoration: T.oneOf(['left', 'right', 'top', 'bottom', 'none']),
  url: T.object,
  attribution: T.shape({
    name: T.string,
    url: T.string
  }),
  caption: T.string
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: video
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockVideo = ({ size, url, attribution, caption }) => {
  const src = url.publicURL;

  const content = (
    <MediaVideo
      size={size}
      src={src}
      attribution={attribution}
      dangerouslySetInnerHTML={{ __html: caption }}
    />
  );
  return !size || size === 'default' ? (
    <FluidConstrainer>{content}</FluidConstrainer>
  ) : (
    content
  );
};

BlockVideo.propTypes = {
  size: T.oneOf(['large', 'default']),
  url: T.object,
  attribution: T.shape({
    name: T.string,
    url: T.string
  }),
  caption: T.string
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block type: Diptych (alpha | beta)
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const BlockDiptych = ({ type, title, body, media, decoration }) => {
  const alt = get(media, 'alt');

  // Some images like gif and svg can't be processes by ImageSharp. In those
  // cases use the unprocessed version.
  const srcProps = media.url.childImageSharp
    ? {
        fluid: media.url.childImageSharp.fluid
      }
    : {
        src: media.url.publicURL
      };

  return (
    <Diptych
      noDecoration={decoration === 'none'}
      layout={type === 'diptych-alpha' ? 'alpha' : 'beta'}
      title={title}
      dangerouslySetInnerHTML={{ __html: body }}
      {...srcProps}
      alt={alt || 'Image'}
    />
  );
};

BlockDiptych.propTypes = {
  type: T.string,
  title: T.string,
  body: T.string,
  decoration: T.string,
  media: T.shape({
    alt: T.string,
    url: T.object
  })
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// Block layout
// Responsible for rendering the correct block type.
// When a new block is created it must be added to the "blockTypeMap".
// // // // // // // // // // // // // // // // // // // // // // // // // // //

const blockTypeMap = {
  text: BlockText,
  'pull-quote': BlockPullQuote,
  'feature-list': BlockFeatureList,
  'number-list': BlockNumberList,
  image: BlockImage,
  'diptych-alpha': BlockDiptych,
  'diptych-beta': BlockDiptych,
  video: BlockVideo
};

const BlockLayout = ({ blocks }) => {
  return blocks.map((block, idx) => {
    // The blocks will never change order between renders so it's safe to use
    // the array index as the key.
    const k = `block-${idx}`;
    const TheBlock = blockTypeMap[block.type];

    return TheBlock ? (
      <TheBlock key={k} {...block} />
    ) : (
      <MissingBlock key={k}>Unknown block type: {block.type}</MissingBlock>
    );
  });
};

BlockLayout.propTypes = {
  blocks: T.arrayOf(
    T.shape({
      type: T.string
    })
  )
};

export default BlockLayout;
