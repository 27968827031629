import React, { useEffect } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { Masonry } from 'masonic';
import { Button } from '@devseed-ui/button';
import { glsp, media } from '@devseed-ui/theme-provider';

import useMediaAware from '../../utils/media-query-aware-hook';

import { ContinuumFold } from '../../styles/fold-continuum';
import {
  FoldInner,
  FoldSuptitle,
  FoldLead,
  FoldBody,
  FoldBlock,
  FoldTitle
} from '../../styles/fold';
import { CardProject } from '../../components/cards';
import { MasonryItemsListElement } from '../../styles/masonry';

const FluidButton = styled(Button)`
  justify-content: center;
`;

const ProjectsFoldBody = styled.div`
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-column: content-3 / content-end;
  `}
`;

const FoldHeadline = styled.div`
  display: flex;
  align-items: center;
`;

const FoldActions = styled.div`
  width: 50%;
  display: flex;
  justify-content: right;
`;

const ProjectsFoldHeader = styled.header`
  grid-column: content-start / content-end;
  display: flex;
  flex-flow: column;
  gap: ${glsp()};

  ${media.mediumUp`
    grid-column: content-2 / content-end;
    gap: ${glsp(2)};
  `}

  ${FoldTitle} {
    width: 50%;
  }

  ${FoldLead} {
    width: 50%;
  }
`;

const projectItemsRenderFn = ({ index, data }) => {
  const { slug } = data;
  const { title, media } = data.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  const { client } = data.frontmatter;
  return (
    <CardProject
      title={title}
      slug={slug}
      cardImage={cardImage}
      client={client}
      index={index + 1}
      skin='dark'
    />
  );
};

export default function SectionProjects(props) {
  const { projectList } = props;

  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  if (!projectList.length) return null;

  return (
    <ContinuumFold>
      <FoldInner>
        <ProjectsFoldHeader>
          <FoldSuptitle>How it works</FoldSuptitle>
          <FoldHeadline>
            <FoldTitle>Our projects</FoldTitle>
            <FoldActions>
              <FluidButton variation='primary-outline' box='semi-fluid'>
                View all Projects
              </FluidButton>
            </FoldActions>
          </FoldHeadline>
          <FoldLead>
            <p>
              See how our partners are tackling some of the worlds hardest
              problems with us.
            </p>
          </FoldLead>
        </ProjectsFoldHeader>
        <FoldBody>
          <FoldBlock>
            <ProjectsFoldBody>
              <Masonry
                items={projectList}
                overscanBy={Infinity}
                columnGutter={
                  media
                    ? media.isXsmallDown()
                      ? 16
                      : media.isLargeDown()
                      ? 32
                      : 48
                    : null
                }
                columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
                columnWidth={288}
                as={MasonryItemsListElement}
                itemAs='li'
                itemKey={(item) => item.id}
                render={projectItemsRenderFn}
              />
            </ProjectsFoldBody>
          </FoldBlock>
        </FoldBody>
      </FoldInner>
    </ContinuumFold>
  );
}

SectionProjects.propTypes = {
  projectList: T.array
};
