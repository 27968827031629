import React from 'react';
import T from 'prop-types';
import get from 'lodash.get';
import styled, { css } from 'styled-components';
import { themeVal, glsp, media } from '@devseed-ui/theme-provider';

import UniversalGridder from '../styles/universal-gridder';
import Prose from '../styles/typography/prose';
import DangerouslySetHtmlContent from './dangerously-set-html-content';

const LongFormSelf = styled(UniversalGridder)`
  grid-row-gap: ${glsp(2)};

  ${media.mediumUp`
    grid-row-gap: ${glsp(4)};
  `}

  ${({ hasQuote }) =>
    !hasQuote &&
    css`
      ${LongFormCopy} {
        ${media.largeUp`
          grid-column-start: content-3;
        `}
      }
    `}

  .twitter-tweet {
    margin-left: auto;
    margin-right: auto;
  }
`;

const LongFormCopy = styled(Prose)`
  grid-column-start: content-start;
  grid-column-end: span 4;

  ${media.mediumUp`
    grid-column-end: span 8;
  `}
`;

const LongFormPullQuote = styled.div`
  position: relative;
  grid-column-start: content-start;
  grid-column-end: span 4;
  align-self: end;
  font-family: ${themeVal('type.heading.family')};
  text-transform: ${themeVal('type.heading.case')};
  font-weight: ${themeVal('type.heading.weight')};
  font-style: ${themeVal('type.heading.style')};
  font-variation-settings: ${themeVal('type.heading.settings')};
  font-size: 2.25rem;
  line-height: 2.75rem;
  padding-top: ${glsp(2.5)};

  ${media.mediumUp`
    grid-column-end: span 5;
    font-size: 2.5rem;
    line-height: 3rem;
  `}

  ${media.largeUp`
    grid-column-start: content-10;
    grid-column-end: span 3;
    font-size: 2.75rem;
    line-height: 3.25rem;
  `}

  ${media.xlargeUp`
    font-size: 3rem;
    line-height: 3.5rem;
  `}

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: ${glsp(8)};
    height: 0.5rem;
    background: ${themeVal('type.base.color')};
  }
`;

const LongForm = (props) => {
  const { className, pullQuote, children, dangerouslySetInnerHTML } = props;
  const html = get(dangerouslySetInnerHTML, '__html');
  // Use html if provided, otherwise default to children.
  // HTML is needed for the rendered markdown content.
  return (
    <LongFormSelf hasQuote={!!pullQuote} className={className}>
      {html ? (
        <DangerouslySetHtmlContent element={LongFormCopy} html={html} />
      ) : (
        <LongFormCopy>{children}</LongFormCopy>
      )}
      {pullQuote && <LongFormPullQuote>{pullQuote}</LongFormPullQuote>}
    </LongFormSelf>
  );
};

LongForm.propTypes = {
  dangerouslySetInnerHTML: T.object,
  className: T.string,
  pullQuote: T.string,
  children: T.node
};

export default LongForm;
