import React from 'react';
import T from 'prop-types';
import { Link, graphql } from 'gatsby';
import get from 'lodash.get';
import { Button } from '@devseed-ui/button';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageSuptitle,
  InpageBody,
  InpageLead
} from '../../styles/inpage';
import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionLead,
  SectionBody
} from '../../styles/section';
import { Widont } from '../../components/widont';
import BlockLayout from '../../components/block-layout';
import Media from '../../components/media';
import { CallToAction, CTAActions } from '../../components/call-to-action';
import SectionProjects from './_section-projects';

const ExpertiseSingleTemplate = ({ data, location }) => {
  const { expertiseProjects, expertise } = data;
  const { title, lead, teaser, contentSection, media } = expertise.frontmatter;

  const coverImage = get(media, 'cover.url.childImageSharp.fluid');
  const coverImageAttribution = get(media, 'cover.attribution');

  return (
    <Layout
      location={location}
      title={title}
      description={teaser}
      metaImage={coverImage && coverImage.src}
    >
      <Inpage>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>
                <Widont>{title}</Widont>
              </InpageTitle>
              <InpageSuptitle>
                <Link to='/expertise' title='View expertise page'>
                  Expertise
                </Link>
              </InpageSuptitle>
            </InpageHeadline>
            <InpageLead>
              <p>{lead}</p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded={!!coverImage}>
          {coverImage && (
            <Media
              size='cover'
              decoration='bottom'
              fluid={coverImage}
              attribution={coverImageAttribution}
              alt='Expertise cover image'
            />
          )}
          <Section>
            <SectionHeader>
              <SectionHeadline>
                <SectionTitle>
                  <Widont>{contentSection.title}</Widont>
                </SectionTitle>
                <SectionSuptitle>{contentSection.subtitle}</SectionSuptitle>
              </SectionHeadline>
              <SectionLead>
                <p>{contentSection.lead}</p>
              </SectionLead>
            </SectionHeader>
            <SectionBody>
              <BlockLayout blocks={contentSection.blocks} />
            </SectionBody>
          </Section>
          <SectionProjects projectList={expertiseProjects.nodes} />

          <CallToAction
            title='Have a challenging project that could use our help?'
            suptitle="Let's connect"
          >
            <p>We&apos;d love to hear from you.</p>
            <CTAActions>
              <Button
                variation='primary-fill'
                size='large'
                forwardedAs={Link}
                to='/contact'
              >
                Contact us
              </Button>
            </CTAActions>
          </CallToAction>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default ExpertiseSingleTemplate;

ExpertiseSingleTemplate.propTypes = {
  data: T.object,
  location: T.object
};

export const pageQuery = graphql`
  query ExpertiseById($id: String!) {
    expertise: expertise(id: { eq: $id }) {
      id
      frontmatter {
        title
        lead
        teaser
        contentSection {
          subtitle
          title
          lead
          blocks {
            ...BlockLayout
          }
        }
        media {
          ...CoverImageFluid
        }
      }
    }

    expertiseProjects: allProject(
      filter: {
        published: { eq: true }
        frontmatter: { expertise: { elemMatch: { id: { eq: $id } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          client {
            name
            url
          }
          media {
            ...CardImageProjectFluid
          }
        }
        slug
      }
    }
  }
`;
